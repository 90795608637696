/* ---------- SETUP ---------- */
* { 
	box-sizing: border-box;
	font-family: "Open Sans", serif;
}

html > * { 
	font-size: 100%; /* 1rem = 16px */
	margin: 0; 
	padding: 0;
}

/* root colors */
:root {
    --black: #000000;
	--gray:#414042;
    --peach: #FFAC8E;
    --pink: #FF8588;
    --tan: #FFE3D4;
    --white: #FFFFFF;
}

/* ---------- STYLING ---------- */
/* body */
body {
	background: var(--white);
	color: var(--black);
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-height: 100vh;
	width: 100vw;
	background-image: url(assets/white-heart.jpg);
	background-repeat: no-repeat;
	background-position: 1000px 75%;
	max-width: 2000px;
}

main {
	align-items: center;
	display: flex;
	height: 100%;
	min-height: max-content;
	width: 100vw;
	justify-content: start;
	max-width: 1700px;
	margin-left: 15%;
}

.kora-wrapper {
	align-items: center;
	display: flex;
	flex-direction: row;
	height: auto;
	width: max-content;
}

.text-wrapper {
	display: flex;
	flex-direction: column;
	min-width: 465px;
	max-width: 465px;
	margin-left: 5.75rem;
}

/* images */
img {
	height: auto;
	width: 100%;
}

.kora-logo img {
	width: 8rem;
}

/* text */

h1 { 
	color: var(--pink);
	font-weight: 700; 
	font-size: 1.875rem; /*30px*/ 
	font-family: 'Prompt', sans-serif;
	text-transform: uppercase;
}

h2 { 
	font-weight: 400; 
	font-size: 1.5626rem; /*25px*/ 
}

p { 
	font-weight: 300; 
	font-size: 1.25rem; /*20px*/  
}

/* form */
form {
	display: flex;
	flex-direction: row;
	padding-top: 4.5rem;
}

form > * {
	font-size: .9375rem; /*15px*/ 
	max-height: 100px;
}

.email {
    border: none;
	border-bottom: 1.5px solid #22262B;
    padding: 10px 0 10px 10px;
	width: 100%;
	min-height: 100%;
	transition: border .25s linear, box-shadow .25s linear;
}

.email::before {
	border-color: var(--peach);
}

.email:focus {
	outline: none !important;
	border: none;
	box-shadow: inset 0 0 0 3.5px var(--pink);
	border-radius: 10px;
}

.email-wrapper {
	width: 65%;
	min-height: 50px;
	max-height: 50px;
}

.email-wrapper:focus-within {
	border: none;
}

/*hide submit button until captcha*/
.submithide {
	background-color: transparent;
	border: transparent;
	display: none;
}
.subscribe {
	animation: gradient 8s ease infinite;
	border: none;
	background: linear-gradient(210deg, var(--pink), var(--peach), var(--tan));
    background-size: 300% 300%;
	font-weight: 700;
	border-radius: 10px;
	color: var(--black);
	margin-left: 3rem;
	padding: .75rem 0;
	transition: opacity 0.225s, box-shadow 0.25s;
	width: 35%;
	height: max-content;
}
.subscribe:hover {
	background: linear-gradient(210deg, var(--peach), var(--tan));
}

.subscribe:active {
	background: linear-gradient(210deg, var(--peach), var(--pink));
	box-shadow: 0 0 20px var(--pink);
	opacity: 100%;
}

/* recaptcha */
.hiderecaptcha {
	display: none;
}

.g-recaptcha {
	position: absolute;
	top: 50;
}

/* footer */
footer { 
	animation: gradient 5s ease infinite;
	align-items: center;
	background: linear-gradient(210deg, var(--pink), var(--peach), var(--tan));
	background-size: 300% 300%;
	display: flex;
	flex-direction: row;
	font-size: .9375rem; /*15px*/ 
	min-height: 5.5rem;
	max-height: 5.5rem;
	justify-content: space-between;
	padding: 0 4rem;
	min-width: 100vw;
	bottom: 0;

}

footer a { 
	color: var(--black);
	cursor: pointer;
	font-style: normal;
	font-weight: 900;
	transition: text-decoration-color 0.25s;
	text-decoration: underline;
	text-decoration-color: rgba(20,20,20,0);
  }

  footer a:hover { 
	text-decoration-color: rgba(20,20,20,1);
  }

  address {
	font-style: normal;
  }

  @media screen and (max-width: 700px) {
	  footer {
		position: fixed;
		left: 0;
		bottom: 0;
	  }
	  form {
		  padding-top: 2rem;
	  }
	  main {
		  height: 90vh;
	  }
  }

  @media screen and (max-height: 700px) 
  				and (orientation: landscape) {
	form {
		padding: 1rem 0 2rem 0;
	}
  }


/* ---------- ANIMATIONS ---------- */
/* gradient animation for footer and button */
@keyframes gradient {
    0% {background-position: 0% 50%;}
    40% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
}

/* form submission success and failure animations */
.recaptcha-pass #email, .recaptcha-pass #subscribe {
	animation: hideform 1s ease forwards 1;
}

.success-animation .g-recaptcha {
	animation: hideform 1s ease forwards 1;
}

@keyframes hideform { /*hides form and recaptcha*/
    0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/*success animation and styles*/
.success-animation .successanim{
	display: initial;
	position: absolute;
	top: 80%;
	left: 32%;
	display: flex;
	flex-direction: row;
    align-items: center;
	width: max-content;
}

.successanim {
    display: flex;
	flex-direction: row;
    align-items: center;
	display: none;
}

.successanim > * {
	margin: 0;
	padding: 0;
}

svg {
    width: 4.5rem;
    height: auto;
}

.check {
    width: 3.5rem;
    height: auto;
    margin-left: -3.4rem;
    margin-bottom: 2rem;
}

.successanim path {
    fill:none;
    stroke: url(#KoraGradient);
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:8px;
    stroke-dasharray: 395;
    stroke-dashoffset: 395;
    animation: heart 2s cubic-bezier(0.42, 0, 0.26, 0.99) forwards;
    animation-iteration-count: 1;
}

@keyframes heart {
    0% {
        stroke-dashoffset: 395;
    }
    50% {
        stroke: url(#KoraGradient);
    }
    75% {
        stroke: url(#KoraGradient2);
    }
    100% {
        stroke-dashoffset: 0;
        stroke: url(#KoraGradient3);
    }
}

.successanim polyline {
    fill:none;
    stroke: url(#KoraGradient);
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:5px;
    stroke-dasharray: 395;
    stroke-dashoffset: 395;
    animation: check 2.5s cubic-bezier(0.42, 0, 0.26, 0.99) forwards;
    animation-iteration-count: 1;
}

@keyframes check {
    0% {
        stroke-dashoffset: 395;
        stroke: url(#KoraGradient);
    }
    30% {
        stroke-dashoffset: 395;
        stroke: url(#KoraGradient);
    }
    70% {
        stroke: url(#KoraGradient2);
    }
    100% {
        stroke-dashoffset: 0;
        stroke: url(#KoraGradient3);
    }
}

.successanim p { 
    color: var(--pink);
	font-weight: 500; 
	font-size: 1rem; /*16px*/ 
	font-family: 'Prompt', sans-serif;
	text-transform: uppercase;
    animation: text 2.5s cubic-bezier(0.42, 0, 0.26, 0.99) forwards;
    animation-iteration-count: 1;
    opacity: 0;
	padding-left: .5rem;
}

@keyframes text{
	0%{
		opacity: 0;
	}
	30%{
        opacity: 0;
        transform: translate(-50px,0);
	}
	70%{
		opacity: 1;
		transform: translate(0,0);
	}
    100%{
		opacity: 1;
		transform: translate(0,0);
	}
}

/* error animation and styles */
.error-animation {
	opacity: 1;
	animation: error .8s cubic-bezier(0.42, 0, 0.26, 0.99) forwards 1;
}

@keyframes error {
    0%{
        transform: translate(0,0);
	}
	10%{
		transform: translate(20px,0);
	}
	20%{
		transform: translate(-20px,0);
	}
	30%{
		transform: translate(20px,0);
	}
	40%{
		transform: translate(-20px,0);
	}
	50%{
		transform: translate(20px,0);
	}
	60%{
		transform: translate(-20px,0);
	}
	80%{
		transform: translate(0,0);
	}
    100%{
		transform: translate(0,0);
	}
}

.erroranim {
	display: none;
}

.error-animation .erroranim {
	display: initial;
	width: max-content;
	position: absolute;
	top: 10%;
	left: 0%;
}

.erroranim p {
	color: var(--pink);
	font-weight: 500; 
	font-size: .85rem; /*16px*/ 
	font-family: 'Prompt', sans-serif;
	text-transform: uppercase;
    animation: errortext 5s cubic-bezier(0.42, 0, 0.26, 0.99) forwards;
    animation-iteration-count: 1;
}

@keyframes errortext{
	0%{
        opacity: 0;
        transform: translate(0,50px);
	}
    20%{
		opacity: 1;
		transform: translate(0,0);
	}
	80%{
		opacity: 1;
		transform: translate(0,0);
	}
	100%{
        opacity: 0;
        transform: translate(0,50px);
	}
}

 /* slide in animation */
 .slide-in-element > * {
	animation-name: slidein;
	animation-duration: .75s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	opacity: 0;
  }

  .slide-in-element > *:nth-child(2) { animation-delay: 0.025s; }
  .slide-in-element > *:nth-child(3) { animation-delay: 0.05s;; }
  .slide-in-element > *:nth-child(4) { animation-delay: 0.075s; }
  .slide-in-element > *:nth-child(5) { animation-delay: 0.1s; }
  .slide-in-element > *:nth-child(6) { animation-delay: 0.125s; }
  .slide-in-element > *:nth-child(7) { animation-delay: 0.15s; }
  .slide-in-element > *:nth-child(8) { animation-delay: 0.175s; }
  .slide-in-element > *:nth-child(9) { animation-delay: 0.2s; }
  .slide-in-element > *:nth-child(10) { animation-delay: 0.225s; }
  .slide-in-element > *:nth-child(11) { animation-delay: 0.25s; }
  .slide-in-element > *:nth-child(12 + n) { animation-delay: 0.275s; }

  @keyframes slidein {
	0% {
	  opacity: 0;
	  transform: translateX(-40px);
	}

	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }

/* ---------- MEDIA QUERIES ---------- */
@media screen and (max-width: 1000px) {
	html {
		font-size: 90%; /* 1rem = 14.4px */
	}
	.text-wrapper {
		max-width: 420px;
		min-width: 420px;
	}
	.kora-wrapper {
		margin: 0 auto;
	}
}
@media screen and (max-width: 800px) {
	html {
		font-size: 80%; /* 1rem = 12.8px */
	}
	.text-wrapper {
		max-width: 380px;
		min-width: 380px;
	}
}
@media screen and (max-width: 675px) 
			  and (orientation: portrait)  {
	.kora-wrapper {
		align-items: flex-start;
		flex-direction: column;
	}
	.text-wrapper {
		margin: 0;
	}
	footer {
		flex-direction: column;
		justify-content: center;
	}
	form {
		flex-direction: column;
	}
	.email-wrapper {
		margin-bottom: 1rem;
		width: 100%;
	}
	.subscribe {
		align-self: flex-end;
		margin: 0;
		width: 35%;
	}
	.success-animation .successanim{
		left: 0%;
	}
}

@media screen and (max-width: 575px) {
	html {
		font-size: 80%; /* 1rem = 12.8px */
	}
	.text-wrapper {
		max-width: 325px;
		min-width: 325px;
	}
	form > *, footer > * {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 415px) {
	html {
		font-size: 75%; /* 1rem = 12px */
	}
	.text-wrapper {
		max-width: 280px;
		min-width: 280px;
	}
	.kora-logo img {
		height: auto;
		width: 80px;
	}
}

/* background image */
@media screen and (max-width: 1500px) {
	body {
		background-position: 760px 75%;
		background-size: 800px;
	}
	main {
		margin-left: 5rem;
	}
}

@media screen and (max-width: 1200px) {
	body {
		background-position: 700px 75%;
	}
}

@media screen and (max-width: 1000px) {
	body {
		background-position: 600px 75%;
		background-size: 700px;
	}
	main {
		margin-left: 5%;
	}
}

@media screen and (max-width: 1000px) {
	body {
		background-image: none;
	}
	main {
		margin: 0 auto;
	}
}